<template>
  <div id="body">
    <div class="header">
      <div class="hader_title">
        <div style="margin-top: 26px;">
          <img src="../../assets/APP_logo.png" alt="" />
          <div style="float: right;margin-top: 26px;margin-left: 26px;">{{ agent }}
            <br>
            ID:{{agentID}}
          </div>
          <div class="title_text">
<!--            <el-popover style=" float:left;margin-left: -24px;" placement="top-start" title="系统联系人" width="70"
              trigger="hover" content="">
              <span>{{this.agentAdmin.name}}</span>
              <br>
              {{this.agentAdmin.tel}}
              <el-image src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTY0NjYyMjkyOC45NTYxLjExNjc3"
                slot="reference"></el-image>
            </el-popover>
            <el-popover style=" float:left;margin-left: 24px;" title="微信二维码" placement="top-start" width="70"
              trigger="hover" content="">
              <el-image style="width: 100px; height: 100px ;margin-left: 10px;" :src="this.agentAdmin.pic"></el-image>
              <br>
              <el-image src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTY0NjYyMjkyMy43NjIzLjQ0NDc5"
                slot="reference"></el-image>
            </el-popover> -->
            <!-- <p>代理等级：{{ agentGrade }}级</p> -->
          </div>
        </div>
        <div>

        </div>
      </div>
      <div class="datalist">
        <div>
          <!--          <p style="color: #ff5a21">{{stuNum}}</p>
          <p>学生认证人数</p> -->
          <p style="color: #ff5a21 ;cursor: pointer;" @click="xxtz()">{{SchoolNum}}</p>
          <p>学校数量</p>
        </div>
        <div>
          <!--          <p style="color: #ff5a21">{{guaNum}}</p>
          <p>家长认证人数</p> -->
          <p style="color: #ff5a21; cursor: pointer;" @click="sbtz()">{{facilityNum}}</p>
          <p>设备数量</p>
        </div>
        <div>
          <!--          <p style="color: #ff5a21">{{machineNum}}</p>
          <p>终端推送数量</p> -->
          <p style="color: #ff5a21">{{stuNum}}</p>
          <p>学生数</p>
        </div>
        <div>
          <!--          <p style="color: #ff5a21">{{detectionNum}}</p>
          <p>检测数量</p> -->
          <p style="color: #ff5a21">{{parentNum}}</p>
          <p>家长数量</p>
        </div>
        <div>
          <p style="color: #3aa0ff">{{liveness}}</p>
          <p>活跃度</p>
        </div>
        <!--        <div>
          <p style="color: #1ac574">{{SchoolNum}}</p>
          <p>学校数量</p>
        </div>
        <div>
          <p style="color: #30cadc">{{facilityNum}}</p>
          <p>设备数量</p>
        </div> -->
      </div>
    </div>

    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left: 20px;">
        <!--为echarts准备一个具备大小的容器dom-->
        <el-tab-pane label="视力检测使用数据" name="first">
          <div>
            <div class="ClassInfo_box">
              <div class="chartTitle">
                <p class="facility">设备检测数</p>
                <ul>
                  <li v-for="(item, index) in dateList1" :key="index" @click="Atpitch1(index,1)"
                    :class="currentIndex1 == index ? 'active' : ''" style="margin-top: 16px;">
                    {{ item }}
                  </li>
                  <li>
                    <el-date-picker v-model="value1" type="daterange" size="small" :picker-options="pickerOptions"
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                      value-format="timestamp">
                    </el-date-picker>
                    <el-button @click="dateDraw(1)" type="primary">查询</el-button>
                  </li>
                </ul>
              </div>
              <div id="main1" style="width: 1200px;height:500%; background: white"></div>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="音视频使用数据" name="second">
          <div id="myChart1">
            <div class="ClassInfo_box">
              <div class="chartTitle">
                <p class="facility">设备检测数</p>
                <ul>
                  <li v-for="(item, index) in dateList2" :key="index" @click="Atpitch1(index,2)"
                    :class="currentIndex2 == index ? 'active' : ''" style="margin-top: 16px;">
                    {{ item }}
                  </li>
                  <li>
                    <el-date-picker v-model="value2" type="daterange" size="small" :picker-options="pickerOptions"
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                      value-format="timestamp">
                    </el-date-picker>
                    <el-button @click="dateDraw(2)" type="primary">查询</el-button>
                  </li>
                </ul>
              </div>
              <div id="main2" style="width: 1200px;height:500%; background: white"></div>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="校园卫士使用数据" name="third">
          <div id="myChart1">
            <div class="ClassInfo_box">
              <div class="chartTitle">
                <p class="facility">设备检测数</p>
                <ul>
                  <li v-for="(item, index) in dateList3" :key="index" @click="Atpitch1(index,3)"
                    :class="currentIndex3 == index ? 'active' : ''" style="margin-top: 16px;">
                    {{ item }}
                  </li>
                  <li>
                    <el-date-picker v-model="value3" type="daterange" size="small" :picker-options="pickerOptions"
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                      value-format="timestamp">
                    </el-date-picker>
                    <el-button @click="dateDraw(3)" type="primary">查询</el-button>
                  </li>
                </ul>
              </div>
              <div id="main3" style="width: 1200px;height:500%; background: white"></div>
            </div>
          </div>

        </el-tab-pane>

        <el-tab-pane label="验光仪使用数据" name="fourth">
          <div id="myChart1">
            <div class="ClassInfo_box">
              <div class="chartTitle">
                <p class="facility">设备检测数</p>
                <ul>
                  <li v-for="(item, index) in dateList4" :key="index" @click="Atpitch1(index,4)"
                    :class="currentIndex4 == index ? 'active' : ''" style="margin-top: 16px;">
                    {{ item }}
                  </li>
                  <li>
                    <el-date-picker v-model="value4" type="daterange" size="small" :picker-options="pickerOptions"
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                      value-format="timestamp">
                    </el-date-picker>
                    <el-button @click="dateDraw(4)" type="primary">查询</el-button>
                  </li>
                </ul>
              </div>
              <div id="main4" style="width: 1200px;height:500%; background: white"></div>
            </div>
          </div>

        </el-tab-pane>

        <el-tab-pane label="收益" name="fifth">
          <div id="myChart1">
            <div class="ClassInfo_box">
              <div class="chartTitle">
                <p class="facility"></p>
                <ul>
                  <li v-for="(item, index) in dateList5" :key="index" @click="Atpitch1(index,5)"
                    :class="currentIndex5 == index ? 'active' : ''" style="margin-top: 16px;">
                    {{ item }}
                  </li>
                  <li>
                    <el-date-picker v-model="value5" type="daterange" size="small" :picker-options="pickerOptions"
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                      value-format="timestamp">
                    </el-date-picker>
                    <el-button @click="dateDraw(5)" type="primary">查询</el-button>
                  </li>
                </ul>
              </div>
              <div id="main5" style="width: 1200px;height:500%; background: white"></div>
            </div>
          </div>

        </el-tab-pane>


      </el-tabs>
    </div>
  </div>

</template>

<script>
  import {
    Agentindex,
    authenticatedList,
    machineTestChart,
    agentAdmin,
    machineDetailseyedaypic,
    machineDetailsvideodaypic,
    machineDetailscameradaypic,
    machineDetailsygydaypic,
    agentProfitpic
  } from '@/api/agentApi.js'
  import echarts from 'echarts'
  export default {
    data() {
      return {
        gongzhang: true, //第一次可以画公章 之后变为false 不能重复画
        activeName: 'first', //tab标签页 默认选择第一个
        charts: '',
        agent: '', // 代理人
        agentID: '', // 代理ID
        agentAdmin: {}, // 系统联系人
        // agentGrade: '25', // 代理等级
        detectionNum: '0', // 检测数量
        liveness: '0', // 活跃度
        SchoolNum: '0', // 学校数量
        facilityNum: '0', // 设备数量
        stuNum: '0', // 学生认证数量
        guaNum: '0', // 家长认证数量
        machineNum: '0', // 终端推送数量
        parentNum: 0, //家长数量
        currentIndex1: 0, // 视力检查索引
        currentIndex2: 0, // 音视频索引
        currentIndex3: 0, // 校园卫士索引
        currentIndex4: 0, // 验光仪索引
        currentIndex5: 0, // 收益索引
        dateList1: ['近3天', '近7天', '近30天'], // Table选项视力检查
        dateList2: ['近3天', '近7天', '近30天'], // Table选项音视频
        dateList3: ['近3天', '近7天', '近30天'], // Table选项校园卫士
        dateList4: ['近3天', '近7天', '近30天'], // Table选项验光仪
        dateList5: ['近3天', '近7天', '近30天'], // Table选项收益
        value1: '', //视力检查日期
        value2: '', //音视频日期
        value3: '', //校园卫士日期
        value4: '', //验光仪日期
        value5: '', //选项收益日期
        Series: [], // 折线图Y轴数据
        Dates: [], // 折线图X轴数据
        tabname: "",
        endDate: "",
        startDate: "",
        choiceDate0: '',
        pickerOptions: {
          // 设置不能选择的日期
          onPick: ({
            maxDate,
            minDate
          }) => {
            this.choiceDate0 = minDate.getTime();
            if (maxDate) {
              this.choiceDate0 = '';
            }
          },
          disabledDate: (time) => {
            let choiceDateTime = new Date(this.choiceDate0).getTime();
            const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
            const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
            const min = minTime;
            const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
            const max = newDate < maxTime ? newDate : maxTime;
            //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
            if (this.choiceDate0) {
              return time.getTime() < min || time.getTime() > max;
            }
            //若一个日期也没选中 则 返回 当前日期以前日期可选
            return time.getTime() > newDate;
          }
        },
      }
    },
    mounted() {
      this.Agentindex(); // 获取头部信息数据
      this.getAuthenticated(); //获取头部认证数据
      this.machineTestChart(1); // 默认今日数据
      // this.AgentAdmin() // 获取系统联系人
      this.drawLine(1, 1) //视力检查折线图
      this.drawLine(2, 1) //音视频折线图
      this.drawLine(3, 1) //校园卫士折线图
      this.drawLine(4, 1) //验光仪折线图
      this.drawLine(5, 1) //收益折线图
    },
    methods: {
      Agentindex() { // 头部设备数据
        Agentindex().then(res => {
          this.agentID = res.data.agentID // 代理ID
          this.agent = res.data.agentName // 代理人
          this.detectionNum = res.data.testNum // 检测数量
          this.liveness = res.data.activityNum // 活跃度
          this.SchoolNum = res.data.schoolNum // 学校数量
          this.parentNum = res.data.parentNum // 家长数量
          this.facilityNum = res.data.machineNum // 设备数量
        }).catch(err => {
          console.log(err)
        })
      },
      async getAuthenticated() { //获取头部认证数据
        let res = await authenticatedList(); //请求接口
        this.stuNum = res.data.stuNum; // 学生认证数量
        this.guaNum = res.data.guaNum; // 家长认证数量
        this.machineNum = res.data.machineNum; // 终端推送数量
      },
      AgentAdmin() { // 获取系统联系人
        agentAdmin().then(res => {
          this.agentAdmin = res.data.list;
        }).catch(err => {
          console.log(err)
        })
      },
      handleClick(tab, event) {
        // console.log(tab.name); //获取选中的标签页name 然后进行操作 查询
        this.tabname = tab.name;
      },
      drawLine(machinetype, datatype) { //画折线图
        var data = {};
        // **************** 视力检查 ****************
        if (machinetype == 1) {

          if (datatype == 4) {
            if (this.value1 == null || this.value1 == "") {
              this.$message.error('请选择日期');
              return false;
            }
            data = {
              dataType: datatype,
              startDate: this.value1[0] / 1000,
              endDate: this.value1[1] / 1000
            }
          } else {
            data = {
              dataType: datatype,
            }
          }

          machineDetailseyedaypic(data).then(res => {
            var eyedata = res.data.date
            var eyenum = res.data.num
            this.charts = echarts.init(document.getElementById('main1'))
            this.charts.setOption({
              tooltip: {
                trigger: 'axis'
              },
              legend: { //对应下面的 name
                data: ['视力检测量'], //变量和下面series name对应
              },
              grid: {
                left: '10%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: { //表示 x 轴
                type: 'category',
                boundaryGap: false,
                data: eyedata //后台返回时间数组
              },
              yAxis: { //表示 y轴
                type: 'value',
                // axisLabel : { //让y轴 变成百分比  或者更改% 变成其他的
                // 	show: true,
                // 	interval: 'auto',
                // 	formatter:'{value}%',
                // },
              },
              series: [ //data 后台返回的对应值
                {
                  name: '视力检测量',
                  type: 'line',
                  stack: '总量',
                  data: eyenum //返回数据数组
                }
              ]
            })
          }).catch(err => {
            console.log(err)
          })
        }
        // **************** 音视频 ****************
        else if (machinetype == 2) {
          if (datatype == 4) {
            if (this.value2 == null || this.value2 == "") {
              this.$message.error('请选择日期');
              return false;
            }
            data = {
              dataType: datatype,
              startDate: this.value2[0] / 1000,
              endDate: this.value2[1] / 1000
            }
          } else {
            data = {
              dataType: datatype,
            }
          }

          machineDetailsvideodaypic(data).then(res => {

            var videodate = res.data.date
            var ptelnum = res.data.ptelnum
            var ytelnum = res.data.ytelnum
            var stelnum = res.data.stelnum

            this.charts = echarts.init(document.getElementById('main2'))
            this.charts.setOption({
              tooltip: {
                trigger: 'axis'
              },
              legend: { //对应下面的 name
                data: ['普通电话量', '语音通话量', '视频通话量'], //变量和下面series name对应
              },
              grid: {
                left: '10%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: { //表示 x 轴
                type: 'category',
                boundaryGap: false,
                data: videodate //后台返回时间数组
              },
              yAxis: { //表示 y轴
                type: 'value',
                // axisLabel : { //让y轴 变成百分比  或者更改% 变成其他的
                // 	show: true,
                // 	interval: 'auto',
                // 	formatter:'{value}%',
                // },
              },
              series: [ //data 后台返回的对应值
                {
                  name: '普通电话量',
                  type: 'line',
                  stack: '',
                  data: ptelnum //返回数据数组
                },
                {
                  name: '语音通话量',
                  type: 'line',
                  stack: '',
                  data: ytelnum //返回数据数组
                },
                {
                  name: '视频通话量',
                  type: 'line',
                  stack: '',
                  data: stelnum //返回数据数组
                }
              ]
            })
          }).catch(err => {
            console.log(err)
          })
        }
        // **************** 校园卫士 ****************
        else if (machinetype == 3) {
          if (datatype == 4) {
            if (this.value3 == null || this.value3 == "") {
              this.$message.error('请选择日期');
              return false;
            }
            data = {
              dataType: datatype,
              startDate: this.value3[0] / 1000,
              endDate: this.value3[1] / 1000
            }
          } else {
            data = {
              dataType: datatype,
            }
          }
          machineDetailscameradaypic(data).then(res => {

            var date = res.data.date
            var cnum = res.data.cnum
            var jnum = res.data.jnum

            this.charts = echarts.init(document.getElementById('main3'))
            this.charts.setOption({
              tooltip: {
                trigger: 'axis'
              },
              legend: { //对应下面的 name
                data: ['进校人数', '出校人数'], //变量和下面series name对应
              },
              grid: {
                left: '10%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: { //表示 x 轴
                type: 'category',
                boundaryGap: false,
                data: date //后台返回时间数组
              },
              yAxis: { //表示 y轴
                type: 'value',
              },
              series: [ //data 后台返回的对应值
                {
                  name: '进校人数',
                  type: 'line',
                  stack: '', //如果这写总量 还有下面的也写总量 就会把下面的和这个求和
                  data: jnum //返回数据数组
                },
                {
                  name: '出校人数',
                  type: 'line',
                  stack: '', //如果这写总量 还有上面的也写总量 就会把上面的和这个求和
                  data: cnum //返回数据数组
                }
              ]
            })
          }).catch(err => {
            console.log(err)
          })
        }
        // **************** 验光仪 ****************
        else if (machinetype == 4) {
          if (datatype == 4) {
            if (this.value4 == null || this.value4 == "") {
              this.$message.error('请选择日期');
              return false;
            }
            data = {
              dataType: datatype,
              startDate: this.value4[0] / 1000,
              endDate: this.value4[1] / 1000
            }
          } else {
            data = {
              dataType: datatype,
            }
          }
          machineDetailsygydaypic(data).then(res => {

            var ygydate = res.data.date
            var ygynum = res.data.num

            this.charts = echarts.init(document.getElementById('main4'))
            this.charts.setOption({
              tooltip: {
                trigger: 'axis'
              },
              legend: { //对应下面的 name
                data: ['验光仪检测量'], //变量和下面series name对应
              },
              grid: {
                left: '10%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: { //表示 x 轴
                type: 'category',
                boundaryGap: false,
                data: ygydate //后台返回时间数组
              },
              yAxis: { //表示 y轴
                type: 'value',
                // axisLabel : { //让y轴 变成百分比  或者更改% 变成其他的
                // 	show: true,
                // 	interval: 'auto',
                // 	formatter:'{value}%',
                // },
              },
              series: [ //data 后台返回的对应值
                {
                  name: '验光仪检测量',
                  type: 'line',
                  stack: '总量',
                  data: ygynum //返回数据数组
                }
              ]
            })
          }).catch(err => {
            console.log(err)
          })
        }
        // **************** 收益 ****************
        else if (machinetype == 5) {
          if (datatype == 4) {
            if (this.value5 == null || this.value5 == "") {
              this.$message.error('请选择日期');
              return false;
            }
            data = {
              dataType: datatype,
              startDate: this.value5[0] / 1000,
              endDate: this.value5[1] / 1000
            }
          } else {
            data = {
              dataType: datatype,
            }
          }
          agentProfitpic(data).then(res => {
            var date = res.data.date
            var ts_bonus = res.data.ts_bonus
            var yyp_bonus = res.data.yyp_bonus

            this.charts = echarts.init(document.getElementById('main5'))
            this.charts.setOption({
              tooltip: {
                trigger: 'axis'
              },
              legend: { //对应下面的 name
                data: ['广告收益', '电话收益'], //变量和下面series name对应
              },
              grid: {
                left: '10%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: { //表示 x 轴
                type: 'category',
                boundaryGap: false,
                data: date //后台返回时间数组
              },
              yAxis: { //表示 y轴
                type: 'value',
                // axisLabel : { //让y轴 变成百分比  或者更改% 变成其他的
                // 	show: true,
                // 	interval: 'auto',
                // 	formatter:'{value}%',
                // },
              },
              series: [ //data 后台返回的对应值
                {
                  name: '广告收益',
                  type: 'line',
                  stack: '总量',
                  data: ts_bonus //返回数据数组
                },
                {
                  name: '电话收益',
                  type: 'line',
                  stack: '总量',
                  data: yyp_bonus //返回数据数组
                }
              ]
            })
          }).catch(err => {
            console.log(err)
          })
        }

      },
      Atpitch1(index, machinetype) { // 折线图选择具体时间时候 3天 7天 30天

        if (machinetype == 1) { //视力检测
          this.currentIndex1 = index;
        } else if (machinetype == 2) { //音视频
          this.currentIndex2 = index;
        } else if (machinetype == 3) { //校园卫士
          this.currentIndex3 = index;
        } else if (machinetype == 4) { //验光仪
          this.currentIndex4 = index;
        } else if (machinetype == 5) { //收益
          this.currentIndex5 = index;
        }
        this.drawLine(machinetype, (index + 1))
      },
      dateDraw(machinetype) { //查询日期画折线图
        if (machinetype == 1) {
          this.currentIndex1 = -1;
        } else if (machinetype == 2) {
          this.currentIndex2 = -1;
        } else if (machinetype == 3) {
          this.currentIndex3 = -1;
        } else if (machinetype == 4) {
          this.currentIndex4 = -1;
        } else if (machinetype == 5) {
          this.currentIndex5 = -1;
        }
        this.drawLine(machinetype, 4)
      },
      machineTestChart(dates) { // 折线图日期数据 好像没用
        // console.log(dates)
        // const data = {
        //   dataType: dates // 数据类型 ( 1日 2周 3月 4年 )
        // }
        // machineTestChart(data).then(res => {
        //   // console.log(res);
        //   this.Dates = res.data.date // X轴数据
        //   this.Series = res.data.testNum // Y轴数据
        //   console.log(res.data.date, res.data.testNum)
        //   // this.Series = res.data.rEye;
        //   this.drawChart(divid, machinetype, array)
        // }).catch(err => {
        //   console.log(err)
        // })
      },
      xxtz() { // 跳转页面
        localStorage.setItem('hpath', '/school_list' )
        this.$router.push({
          name: 'school_list',
          params: {
          }
        })
      },
      sbtz() { // 跳转页面
        localStorage.setItem('hpath', '/facilityList' )
        this.$router.push({
          name: 'facilityList',
          params: {
          }
        })
      }


    }
  }
</script>

<style lang="scss" scoped>
  #body {
    min-height: 94%;
    overflow: hidden;
    // background-color: #F0F2F5;
  }

  .header {
    display: flex;
    height: 110px;
    align-items: center;
    padding-bottom: 30px;
    background: #ffffff;

    .hader_title {
      display: flex;
      margin-left: 85px;
      align-items: center;

      .title_text {
        margin-left: 24px;

        p:nth-child(1) {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.85);
          font-family: PingFangSC-Medium;
          font-weight: 600;
        }

        p:nth-child(2) {
          width: 121px;
          height: 27px;
          font-size: 14px;
          font-weight: normal;
          background: #1890FF;
          border-radius: 14px;
          color: #FFFFFF;
          line-height: 27px;
        }
      }

      img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
      }
    }

    .datalist {
      display: flex;
      flex: 1;
      height: 70px;
      margin: 0 200px;
      justify-content: space-between;

      div {
        p:nth-child(1) {
          font-size: 20px;
          font-weight: normal;
        }

        p:nth-child(2) {
          font-size: 14px;
          font-weight: normal;
          color: #000000;
        }
      }
    }
  }

  .active {
    //检测人滑动点击css
    color: #1890FF;
    font-size: 14px;
  }

  .ClassInfo_box {
    width: 96%;
    margin: 0 auto;
    background: white;
    margin-top: 25px;

    .chartTitle {
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;

      .facility {
        font-size: 16px;
        line-height: 24px;
        color: #1890FF;
        margin-left: 32px;
        font-family: PingFangSC-Medium;
      }

      ul {
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-right: 25px;

        li {
          float: left;
          list-style: none;
          padding-left: 33px;
        }

        li:hover {
          cursor: pointer
        }
      }
    }
  }
</style>

